import React from 'react';
import membre1 from '../assets/images/team/membre.png';
import notre_equipe from '../assets/images/notre_equipe.png';

const Equipe = () => {
    return (
        <section id="equipe" className="equipe mt-200">
            <div className="container text-center">
                <div className="row justify-content-center gy-3">
                    <div className="col-lg-12">
                        <h2>RENCONTRE AVEC</h2>
                    </div>
                    <div className="col-lg-12 mt-0">
                        <h1 className="texte-elec">NOTRE ÉQUIPE</h1>
                    </div>
                </div>
                <div className="row justify-content-center gy-3">
                    <div className="col-lg-3 col-md-6">
                        <div className="equipe-item">
                            <img className="equipe-item-img" src={membre1}/>
                            <div className="equipe-item-info">
                                <p className="equipe-item-info-nom">
                                    CHARLOTTE
                                </p>
                                <p className="equipe-item-info-profession">
                                    ASSISTANTE DE DIRECTION
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="equipe-item">
                            <img className="equipe-item-img" src={membre1}/>
                            <div className="equipe-item-info">
                                <p className="equipe-item-info-nom">
                                    CHARLOTTE
                                </p>
                                <p className="equipe-item-info-profession">
                                    ASSISTANTE DE DIRECTION
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="equipe-item">
                            <img className="equipe-item-img" src={membre1}/>
                            <div className="equipe-item-info">
                                <p className="equipe-item-info-nom">
                                    CHARLOTTE
                                </p>
                                <p className="equipe-item-info-profession">
                                    ASSISTANTE DE DIRECTION
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center gy-3">
                    <div className="col-lg-12">
                        <a id="btn-nous-contacter" className="bouton btn-hover" href="#contact">NOUS CONTACTER</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Equipe;